import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Config from "../config/config";
import logo from "../assets/images/icons/logo/white/WhiteShine256px.svg";
import pencilIcon from "../assets/images/icons/logo/black/pencil.svg";
import "./Account.css";
import checkIcon from "../assets/images/icons/logo/black/checkMark.svg";
import crossIcon from "../assets/images/icons/logo/black/crossMark.svg";
import "./Account.css";
import ROLE_IDS from "../config/constants";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NotificationModal from '../components/NotificationModal';




const theme = createTheme({
  palette: {
    primary: {
      main: "#96d2b0",
    },
  },
});



export default function SignIn() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState("");


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isEditingFirstName, setIsEditingFirstName] = useState(false);
  const [isEditingLastName, setIsEditingLastName] = useState(false);
  const [title, setTitle] = useState("");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [discipline, setDiscipline] = useState("");
  const [isEditingDiscipline, setIsEditingDiscipline] = useState(false);

  const [showModal, setShowModal] = useState(false); 
  const [modalContent, setModalContent] = useState({ title: '', message: '' });  



  useEffect(() => {
    const userUUID = localStorage.getItem('user-uuid');
    if (userUUID) {
      axios.get(`${Config.API_URL}/api/users/${userUUID}`, { withCredentials: true })
        .then(response => {
          if (response.status === 200) {
            setUserData(response.data);
            setIsAdmin(response.data?.User_Roles[0]?.roleID === ROLE_IDS.ADMIN);
          }
        })
        .catch(error => {
          console.error("Failed to fetch user details:", error);
        });
    }
  }, []);

  useEffect(() => {
    setLoading(!userData || !userData.User_Roles);
  }, [userData]);

  const showNotification = (title, message) => {
    setModalContent({ title, message });
    setShowModal(true);
  };

  // Format the field name in the notification
  function formatFieldName(field) {
    return field
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
  }

  // function to handle save button click for editing user names, titles, and disciplines
  const handleSave = (field, value, setIsEditing) => {
    axios
      .patch(`${Config.API_URL}/api/users/${userData.uuid}/${field}`, {
        [field]: value,
      }, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          setUserData((prevData) => ({
            ...prevData,
            [field]: value,
          }));
          setIsEditing(false);
          showNotification(`${formatFieldName(field)} Updated`);  
        }
      })
      .catch((error) => {
        console.error(`Failed to update ${field}:`, error);
        showNotification('Update Failed',`Failed to update ${formatFieldName(field)}. Please try again.`);
  
      });
  };

  // function to handle edit button click for editing user names, titles, and disciplines
  const handleClick = (setIsEditing) => {
    setIsEditing(true);
  };
  

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errorMessage || passwordStrengthMessage || !password) return;

    try {
      const response = await axios.put(`${Config.API_URL}/api/auth/change-password/${userData.uuid}`,
        { newPassword: password },
        { withCredentials: true }
      );

      if (response.data.message) {
        alert('Successfully changed password. Please login again.');
        await axios.post(`${Config.API_URL}/api/auth/logout`, {}, { withCredentials: true });
        localStorage.removeItem('access-token');
        localStorage.removeItem('user-role');
        setTimeout(() => {
          navigate('/login');
        }, 500);
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage('An error occurred while changing the password. Please try again.');
    }

    setErrorMessage('');
  };

  // function to handle cancel button clicked
  const handleCancel = (setIsEditing, setValue, originalValue) => {
    setIsEditing(false);
    setValue(originalValue); 
  };  

  const handleDelete = () => {
    if (emailConfirm === userData.email) {
      const userUUID = localStorage.getItem("user-uuid");
      axios
        .delete(`${Config.API_URL}/api/users/user/${userUUID}`, { withCredentials: true })
        .then((response) => {
          if (response.status === 200) {
            alert("Account successfully deleted");
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Failed to delete account:", error);
        });
      setOpen(false);
    } else {
      alert("Email does not match");
    }
  };


  // update handleUpdateSubscription function to handle subscription status
  const handleUpdateSubscription = async () => {
    if (!userData.subscribed) {
      try {
        const response = await axios.post(`${Config.API_URL}/api/create-session`, {
          userId: userData.uuid,
        }, { withCredentials: true });
  
        const { paymentId } = response.data;
        console.log("Generated Payment ID:", paymentId); 

        navigate(`/payment/${paymentId}`);
      } catch (error) {
        console.error("Failed to create payment session:", error);
        alert("Failed to start subscription. Please try again.");
      }
    } else {
      setSubscriptionDialogOpen(true);
    }
  };  

  // function to handle confirm unsubscribe
  const handleConfirmUnsubscribe = async () => {
    try {
      const response = await axios.patch(`${Config.API_URL}/api/users/${userData.uuid}/subscription`, {
        subscribed: false,
        subEndDate: null,
      }, { withCredentials: true });

      if (response.status === 200) {
        // update subscription status in the UI
        setUserData(prevData => ({
          ...prevData,
          subscribed: false,
          subEndDate: null
        }));
        alert("Successfully unsubscribed!");
      }
    } catch (error) {
      console.error("Failed to update subscription:", error);
      alert("Encountered unexpected error");
    }

    setSubscriptionDialogOpen(false);
  };


  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
    } else {
      setErrorMessage('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password && newConfirmPassword && password !== newConfirmPassword) {
      setErrorMessage('Passwords do not match');
    } else {
      setErrorMessage('');
    }
  };

  const checkPasswordStrength = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (password && !passwordRegex.test(password)) {
      setPasswordStrengthMessage('Password must be at least 8 characters long and ' +
        'include at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*).');
    } else {
      setPasswordStrengthMessage('');
    }
  };

  const handleFreeTrial = async () => {
    try {
      const trialEndDate = new Date();
      trialEndDate.setDate(trialEndDate.getDate() + 30); 

      const response = await axios.patch(`${Config.API_URL}/api/users/${userData.uuid}/trial`, {
        trialUsed: true,
        subEndDate: trialEndDate,
        subscribed: true
      }, { withCredentials: true });

      if (response.status === 200) {
        setUserData(prevData => ({
          ...prevData,
          trialUsed: true,
          subscribed: true,
          subEndDate: trialEndDate
        }));
        showNotification("Free Trial Started", "You have successfully started a one-month free trial!");
      }
    } catch (error) {
      console.error("Failed to start free trial:", error);
      alert("encountered unexpected error");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ width: 90, height: 90, bgcolor: '#96d2b0', border: '3px solid #5a8e70' }}>
            <img src={logo} className={'height-width-5rem'} alt='GPGC Logo'></img>
          </Avatar>

          <Typography
            component="h1"
            variant="h5"
            sx={{ mt: 3, mb: 2, color: "#68a783" }}
          >
            Edit Account
          </Typography>

          <div style={{
            color: "black",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            {!loading ? (
            <>

              <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                <Typography variant="body2" component="span" sx={{ width: '23%', textAlign: "left", marginRight: "2rem" }}>
                  First Name:
                </Typography>
                {isEditingFirstName ? (
                  <TextField
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    sx={{ width: '120px', height: '40px', marginRight: "0.5rem" }} 
                    InputProps={{ sx: { height: '40px' } }}
                  />
                ) : (
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                    {userData.firstName}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  // sx={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }} 
                  onClick={isEditingFirstName
                    ? () => handleSave('firstName', firstName, setIsEditingFirstName)
                    : () => handleClick(setIsEditingFirstName)}
                >
                  <img 
                    src={isEditingFirstName ? checkIcon : pencilIcon} 
                    alt={isEditingFirstName ? "Submit" : "Edit"} 
                    style={{ width: '22px', height: '22px' }} 
                  />
                </Button>
                {isEditingFirstName && (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "0.1rem" }}
                  onClick={() => handleCancel(setIsEditingFirstName, setFirstName, userData.firstName)}
                >
                  <img src={crossIcon} alt="Cancel" style={{ width: '22px', height: '22px' }} />
                </Button>
              )}
              </Box>

              <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                <Typography variant="body2" component="span" sx={{ width: '23%', textAlign: "left", marginRight: "2rem" }}>
                  Last Name:
                </Typography>
                {isEditingLastName ? (
                  <TextField
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    sx={{ width: '120px', height: '40px', marginRight: "0.5rem" }} 
                    InputProps={{ sx: { height: '40px' } }}
                  />
                ) : (
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                    {userData.lastName}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  onClick={isEditingLastName
                    ? () => handleSave('lastName', lastName, setIsEditingLastName)
                    : () => handleClick(setIsEditingLastName)}
                >
                  <img 
                    src={isEditingLastName ? checkIcon : pencilIcon} 
                    alt={isEditingLastName ? "Submit" : "Edit"} 
                    style={{ width: '22px', height: '22px' }} 
                  />
                </Button>
                {isEditingLastName && (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "0.1rem" }}
                  onClick={() => handleCancel(setIsEditingLastName, setLastName, userData.lastName)}
                >
                  <img src={crossIcon} alt="Cancel" style={{ width: '22px', height: '22px' }} />
                </Button>
              )}
              </Box>



              <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                <Typography variant="body2" component="span" sx={{  width: '23%', textAlign: "left", marginRight: "2rem" }}>
                  Title:
                </Typography>
                {isEditingTitle ? (
                  <TextField
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ width: '120px', height: '40px', marginRight: "0.5rem" }} 
                    InputProps={{ sx: { height: '40px' } }}
                  />
                ) : (
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                    {userData.title}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  onClick={isEditingTitle
                    ? () => handleSave('title', title, setIsEditingTitle)
                    : () => handleClick(setIsEditingTitle)}
                >
                  <img 
                    src={isEditingTitle ? checkIcon : pencilIcon} 
                    alt={isEditingTitle ? "Submit" : "Edit"} 
                    style={{ width: '22px', height: '22px' }} 
                  />
                </Button>
                {isEditingTitle && (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "0.1rem" }}
                  onClick={() => handleCancel(setIsEditingTitle, setTitle, userData.title)}
                >
                  <img src={crossIcon} alt="Cancel" style={{ width: '22px', height: '22px' }} />
                </Button>
              )}
              </Box>

              <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                <Typography variant="body2" component="span" sx={{ width: '15%', textAlign: "left", marginRight: "2rem" }}>
                  Discipline:
                </Typography>
                {isEditingDiscipline ? (
                  <FormControl fullWidth sx={{ width: '200px', height: '40px' }}>
                    <Select
                      labelId="discipline-label"
                      id="discipline"
                      value={discipline}
                      onChange={(e) => setDiscipline(e.target.value)}
                      sx={{ height: '40px', marginRight: "0.5rem"}}
                    >
                      <MenuItem value="Medical Student">Medical Student</MenuItem>
                      <MenuItem value="Resident">Resident</MenuItem>
                      <MenuItem value="Family Physician">Family Physician</MenuItem>
                      <MenuItem value="Other Specialist Physician">Other Specialist Physician</MenuItem>
                      <MenuItem value="RN/LPN">RN/LPN</MenuItem>
                      <MenuItem value="Pharmacy Resident">Pharmacy Resident</MenuItem>
                      <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                    {userData.discipline}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  onClick={isEditingDiscipline
                    ? () => handleSave('discipline', discipline, setIsEditingDiscipline)
                    : () => handleClick(setIsEditingDiscipline)}
                >
                  <img 
                    src={isEditingDiscipline ? checkIcon : pencilIcon} 
                    alt={isEditingDiscipline ? "Submit" : "Edit"} 
                    style={{ width: '22px', height: '22px' }} 
                  />
                </Button>
                {isEditingDiscipline && (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "0.1rem" }}
                  onClick={() => handleCancel(setIsEditingDiscipline, setDiscipline, userData.discipline)}
                >
                  <img src={crossIcon} alt="Cancel" style={{ width: '22px', height: '22px' }} />
                </Button>
              )}
              </Box>

              <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                <Typography variant="body2" component="span" sx={{ width: '23%', textAlign: "left", marginRight: "2rem" }}>
                  Email:
                </Typography>
                <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                  {userData.email}
                </Typography>
              </Box>

              {!isAdmin && (
                <Box component="div" sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2  }}>
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", marginRight: "2rem" }}>
                    Subscription Status:
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ textAlign: "left", fontWeight: "bold" }}>
                    {userData.subscribed ? "Active" : "Inactive"}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleUpdateSubscription}
                  >
                    {userData.subscribed ? "Unsubscribe" : "Subscribe"}
                  </Button>
                </Box>
              )}

              {!isAdmin && !userData.subscribed && !userData.trialUsed && (
                <Button
                  variant="contained"
                  sx={{ mt: 2, color: "#000000", backgroundColor: "#96d2b0", marginBottom: 3 }}
                  onClick={handleFreeTrial}
                >
                  Free Trial 1 Month Subscription
                </Button>
              )}

              </>
            ) : (
              <Typography>
                Loading...
              </Typography>
            )}
          </div>

          {!loading && <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>


              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
              {passwordStrengthMessage && (
                <Grid item xs={12}>
                  <Typography color="error">{passwordStrengthMessage}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error">{errorMessage}</Typography>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Submit Changes
            </Button>

            {
              !isAdmin && (
                <Button
                  fullWidth
                  variant="text"
                  sx={{
                    mt: 1,
                    mb: 1,
                    color: "#96d2b0",
                    textDecoration: 'underline',
                  }}
                  onClick={() => setOpen(true)}
                >
                  Delete Account
                </Button>
              )}

          </Box>}
        </Box>
      </Container>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delete your account, please enter your registered email address to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="emailConfirm"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={emailConfirm}
            onChange={(e) => setEmailConfirm(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Discard</Button>
          <Button onClick={handleDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={subscriptionDialogOpen} onClose={() => setSubscriptionDialogOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Are you sure you want to " + (userData.subscribed ? "unsubscribe" : "subscribe") + "?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSubscriptionDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirmUnsubscribe} color="error">
            Confirm
          </Button>
        </DialogActions>

      </Dialog>

      <NotificationModal 
        show={showModal} 
        onClose={() => setShowModal(false)} 
        title={modalContent.title} 
        message={modalContent.message} 
      />


    </ThemeProvider>
  );
}
