import * as React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import logo from '../assets/images/icons/logo/white/WhiteShine256px.svg';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Config from '../config/config';

const theme = createTheme({
  palette: {
    primary: {
      main: '#96d2b0'
    }
  }
});

export default function Register() {
  const navigate = useNavigate();

  const [occupation, setOccupation] = React.useState('');
  const [showSpecialtyInput, setShowSpecialtyInput] = React.useState(false);
  const [showOccupationInput, setShowOccupationInput] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordStrength, setPasswordStrength] = React.useState({ message: '', class: '' });
  const [passwordMatchError, setPasswordMatchError] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  const [is2FA, setIs2FA] = React.useState(false); // 2FA state
  const [twoFACode, setTwoFACode] = React.useState(''); // 2FA code input
  const [registrationData, setRegistrationData] = React.useState(null); // Data to submit after 2FA verification

  const handleOccupationChange = (event) => {
    const discipline = event.target.value;
    setOccupation(discipline);

    if (discipline === 'Other') {
      setShowOccupationInput(true);
      setShowSpecialtyInput(false);
    } else if (discipline === 'Other Specialist Physician') {
      setShowOccupationInput(false);
      setShowSpecialtyInput(true);
    } else {
      setShowOccupationInput(false);
      setShowSpecialtyInput(false);
    }
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    const strength = checkPasswordStrength(passwordValue);
    setPasswordStrength(strength);

    if (confirmPassword && passwordValue !== confirmPassword) {
      setPasswordMatchError('Passwords do not match');
    } else {
      setPasswordMatchError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setConfirmPassword(confirmPasswordValue);
    if (confirmPasswordValue !== password) {
      setPasswordMatchError('Passwords do not match');
    } else {
      setPasswordMatchError('');
    }
  };

  const checkPasswordStrength = (password) => {
    let strength = { message: '', class: '' };

    if (password.length < 6) {
      strength.message = 'Password is too short';
      strength.class = 'weak';
      setIsButtonDisabled(true);
    } else if (password.length < 8) {
      strength.message = 'Password is weak';
      strength.class = 'weak';
      setIsButtonDisabled(true);
    } else if (!/[A-Z]/.test(password)) {
      strength.message = 'Password should include at least one uppercase letter';
      strength.class = 'medium';
      setIsButtonDisabled(true);
    } else if (!/[a-z]/.test(password)) {
      strength.message = 'Password should include at least one lowercase letter';
      strength.class = 'medium';
      setIsButtonDisabled(true);
    } else if (!/[0-9]/.test(password)) {
      strength.message = 'Password should include at least one number';
      strength.class = 'medium';
      setIsButtonDisabled(true);
    } else if (!/[!@#$%^&*]/.test(password)) {
      strength.message = 'Password should include at least one special character';
      strength.class = 'medium';
      setIsButtonDisabled(true);
    } else {
      strength.message = 'Password is strong';
      strength.class = 'strong';
      setIsButtonDisabled(false);
    }

    return strength;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isButtonDisabled) return;
    if (password !== confirmPassword) {
      setPasswordMatchError('Passwords do not match');
      return;
    }
  
    const formData = new FormData(event.target);
    const discipline = formData.get('discipline') || formData.get('specialty') || formData.get('other-discipline');
  
    const registrationPayload = {
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      email: formData.get('email'),
      password: formData.get('password'),
      discipline: discipline,
      title: formData.get('title'),
    };
  
    try {
      // Send registration 2FA code to email
      const response = await axios.post(`${Config.API_URL}/api/auth/send-registration-2fa`, {
        email: registrationPayload.email,
      });
  
      if (response.status === 200) {
        setIs2FA(true); // Move to 2FA step
        setRegistrationData(registrationPayload); // Save registration data to submit later
        alert('A Verification code has been sent to your email.');
      }
    } catch (error) {
      console.error('Error sending registration 2FA code:', error);
      alert(error.response?.data.errorMessage || 'Error sending 2FA code');
    }
  };

  const handle2FASubmit = async (event) => {
    event.preventDefault();
    if (!registrationData) return;
  
    try {
      const response = await axios.post(`${Config.API_URL}/api/auth/verify-registration-2fa`, {
        email: registrationData.email,
        twoFACode,
      });
  
      if (response.status === 200) {
        // Complete registration
        await axios.post(`${Config.API_URL}/api/auth/register`, registrationData);
        alert('Registration successful!');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error verifying registration 2FA code:', error);
      alert(error.response?.data.errorMessage || 'Invalid 2FA code');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ width: 90, height: 90, bgcolor: '#96d2b0', border: '3px solid #5a8e70' }}>
            <img src={logo} className="height-width-5rem" alt="GPGC Logo" />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mt: 3, mb: 2, color: '#68a783' }}>
            Register
          </Typography>

          <Box component="form" onSubmit={is2FA ? handle2FASubmit : handleSubmit} noValidate sx={{ mt: 1 }}>
            {!is2FA ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField required fullWidth id="firstName" label="First Name" name="firstName" autoComplete="given-name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField required fullWidth id="lastName" label="Last Name" name="lastName" autoComplete="last-name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField required fullWidth id="email" label="Email Address" name="email" autoComplete="email" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="discipline-label">Discipline</InputLabel>
                      <Select labelId="discipline-label" id="discipline" value={occupation} label="Discipline" onChange={handleOccupationChange}>
                        <MenuItem value="Medical Student">Medical Student</MenuItem>
                        <MenuItem value="Resident">Resident</MenuItem>
                        <MenuItem value="Family Physician">Family Physician</MenuItem>
                        <MenuItem value="Other Specialist Physician">Other Specialist Physician</MenuItem>
                        <MenuItem value="RN/LPN">RN/LPN</MenuItem>
                        <MenuItem value="Pharmacy Resident">Pharmacy Resident</MenuItem>
                        <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {showSpecialtyInput && (
                    <Grid item xs={12}>
                      <TextField fullWidth id="specialty" label="Specialty" name="specialty" />
                    </Grid>
                  )}
                  {showOccupationInput && (
                    <Grid item xs={12}>
                      <TextField fullWidth id="other-discipline" label="Other Discipline" name="other-discipline" onChange={(e) => setOccupation(e.target.value)} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField required fullWidth name="title" label="Title" id="title" autoComplete="new-title" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <Typography className={passwordStrength.class} sx={{ mt: 1 }}>
                      {passwordStrength.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirm-password"
                      label="Confirm Password"
                      type="password"
                      id="confirm-password"
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {passwordMatchError && (
                      <Typography color="error" sx={{ mt: 1 }}>
                        {passwordMatchError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isButtonDisabled}>
                  Sign Up
                </Button>
              </>
            ) : (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Enter the 2FA code sent to your email
                </Typography>
                <TextField
                  fullWidth
                  required
                  id="twoFACode"
                  label="2FA Code"
                  value={twoFACode}
                  onChange={(e) => setTwoFACode(e.target.value)}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Verify & Register
                </Button>
              </>
            )}
            <Grid container className="signUpGrid">
              <Grid item xs>
                <Link href="/Login" variant="body2" sx={{ color: '#68a783' }}>
                  Already have an Account? | Sign In.
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
