import * as React from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import logo from '../assets/images/icons/logo/white/WhiteShine256px.svg';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Login.css';
import Config from "../config/config";  
import {useState} from 'react';
import ToastComponent from '../components/ToastComponent';
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: {
      main: '#96d2b0'
    }
  }
});

export default function SignIn() { 
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [toastMessage, setToastMessage] = useState('');  
  const [toastType, setToastType] = useState('');
  const navigate = useNavigate();



  if (localStorage.getItem('access-token') && localStorage.getItem('2fa-status')) {
    setTimeout(() => {
      //window.location.href = '/home';
    }, 1000);
  }

   const showToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => {
      setToastMessage('');
      setToastType('');
    }, 9000);
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${Config.API_URL}/api/auth/send-2fa`, { email, password });
      if (response.status === 200) {
        localStorage.setItem("access-token", response.data.token);
        localStorage.setItem("user-uuid", response.data.uuid);
        localStorage.setItem("user-role", response.data.role);
        localStorage.setItem("email", email);
        showToast('2FA code sent to email.', 'info');
        navigate('/two-factor-auth'); // Redirect to 2FA page
      } else {
        showToast(response.data.errorMessage || 'Login failed', 'error');
      }
    } catch (error) {
      console.error('Login Error:', error);
      showToast(error.response?.data.errorMessage || 'An error occurred during login', 'error');
    }
  };



return (
  <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ width: 90, height: 90, bgcolor: '#96d2b0', border: '3px solid #5a8e70' }}>
          <img src={logo} className="height-width-5rem" alt="GPGC Logo" />
        </Avatar>

        <Typography component="h1" variant="h5" sx={{ mt: 3, mb: 2, color: '#68a783' }}>
          Login
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField 
            margin="normal"
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            fullWidth
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
              margin="normal"
              name="password"
              label="Password"
              type="password"
              id="password"
              fullWidth
              required
              onChange={(e) => setPassword(e.target.value)}
          />
          
          <FormControlLabel 
            control={<Checkbox value="remember" color="primary" sx={{ color: '#68a783' }} />}
            label="Remember me"
            sx={{ color: '#68a783' }}
          />
          
          <Button 
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: 'darkgreen' }}
          >
            Sign In
          </Button> 

          <Grid container className="signUpGrid">
            <Grid item xs>
              <Link href="/register" variant="body2" sx={{ color: '#68a783' }}>
                Don't have an account? Sign Up
              </Link>
            </Grid>
            <Grid item>
              <Link href="/ForgotPassword" variant="body2" sx={{ color: '#68a783' }}>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      
      <ToastComponent message={toastMessage} type={toastType} />
    </Container>
  </ThemeProvider>
)};

  
