import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import './Payment.css';
import Config from '../config/config';
import NotificationModal from '../components/NotificationModal'; 
import { useParams } from 'react-router-dom';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

const Payment = () => {
  const navigate = useNavigate();
  const { paymentId } = useParams(); // Get paymentId from URL
  const [isValidPaymentId, setIsValidPaymentId] = useState(false); // Check if paymentId is valid
  const [userData, setUserData] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');

  const [showModal, setShowModal] = useState(false); 
  const [modalContent, setModalContent] = useState({ title: '', message: '' });

  const [subscriptionPeriod, setSubscriptionPeriod] = useState("1"); // Default: 1 month

  const handleReturnToAccount = () => {
    navigate('/account');
  };

  useEffect(() => {
    const validatePaymentId = async () => {
      console.log("Validating Payment ID:", paymentId);
      try {
        const response = await axios.get(`${Config.API_URL}/api/validate-session/${paymentId}`, { withCredentials: true });
        if (response.status === 200) {
          setIsValidPaymentId(true);
          const userUUID = localStorage.getItem('user-uuid');
        if (userUUID) {
          const userResponse = await axios.get(`${Config.API_URL}/api/users/${userUUID}`, { withCredentials: true });
          if (userResponse.status === 200) {
            setUserData(userResponse.data);
          }
        }
        } else {
          console.log("Validation failed:", response.data);
          alert("Invalid or expired payment session.");
          navigate('/account');
        }
      } catch (error) {
        console.error("Error validating payment ID:", error);
        alert("An error occurred. Please try again.");
        navigate('/account');
      }
    };
    validatePaymentId();
  }, [paymentId, navigate]);
  
  

  const handlePaymentSuccess = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements || !isValidPaymentId || !userData.uuid) {
      console.error("Missing required data for payment.");
      return;
    }
  
    const cardNumberElement = elements.getElement(CardNumberElement);
  
    try { 
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          email: email,
        },
      });
  
      if (!paymentMethod) {
        console.error("Failed to create payment method.");
        setModalContent({ title: 'Payment Failed', message: 'Could not validate payment method. Please try again.' });
        setShowModal(true);
        return;
      }
   
      const response = await axios.post(`${Config.API_URL}/api/create-intent`, {
        paymentId,
        subscriptionPeriod,
        paymentMethodId: paymentMethod.id, 
      }, { withCredentials: true });
  
      if (response.status === 200) { 
        await axios.patch(`${Config.API_URL}/api/users/${userData.uuid}/subscription-period`, {
          subscriptionPeriod,
        }, { withCredentials: true });
  
        setModalContent({
          title: "Payment Successful!",
          message: `Your payment for ${
            subscriptionPeriod === "1"
              ? "1 month"
              : subscriptionPeriod === "3"
              ? "3 months"
              : "1 year"
          } was completed successfully.`,
        });
        setShowModal(true);
  
        setTimeout(() => {
          navigate('/account');
        }, 3000);
      }
    } catch (error) {
      console.error("Payment failed:", error);
      setModalContent({ title: 'Payment Failed', message: 'Something went wrong. Please try again.' });
      setShowModal(true);
    }
  };
  

  
  return (
    <div className="payment-page-container">
      {isValidPaymentId ? (
        <> 
        <NotificationModal 
          show={showModal} 
          onClose={() => setShowModal(false)} 
          title={modalContent.title} 
          message={modalContent.message} 
        />

      {/* on the top side: Subscription Information */}
      <div className="subscription-info-area">
        <h2>Subscription Information</h2>
        <p>
          Choose your subscription period below and enjoy exclusive benefits including premium content, early access to new features, and much more.  
        </p>

        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "0.5rem" }}>
          <button
            type="button"
            onClick={() => setSubscriptionPeriod("1")}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: subscriptionPeriod === "1" ? "#96d2b0" : "#f0f0f0",
              border: "1px solid #96d2b0",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            1 Month - $10
          </button>
          <button
            type="button"
            onClick={() => setSubscriptionPeriod("3")}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: subscriptionPeriod === "3" ? "#96d2b0" : "#f0f0f0",
              border: "1px solid #96d2b0",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            3 Months - $25
          </button>
          <button
            type="button"
            onClick={() => setSubscriptionPeriod("12")}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: subscriptionPeriod === "12" ? "#96d2b0" : "#f0f0f0",
              border: "1px solid #96d2b0",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            1 Year - $90
          </button>
        </div>

        <div className='button-container'>
          {/* Return to Account Page Button */}
          <button className="return-button" onClick={handleReturnToAccount}>Return to Account Page</button>
        </div>
      </div>
      
      {/* at the bottom side: Payment Form */}
      <div className="payment-area">
        <h2>Complete Your Payment</h2>
        <form onSubmit={handlePaymentSuccess}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
          </div>

          <div className="form-group">
            <label>Card Number:</label>
            <CardNumberElement className="stripe-input input-field" />
          </div>

          <div className="form-group">
            <label>Expiry Date:</label>
            <CardExpiryElement className="stripe-input input-field" />
          </div>

          <div className="form-group">
            <label>CVC:</label>
            <CardCvcElement className="stripe-input input-field" />
          </div>

          <button type="submit" className="submit-button" disabled={!stripe}>Pay Now</button>
        </form>
      </div>
      </>
    ) : (
      // if paymentId is invalid
      <div className="invalid-payment-id">
        <h2>Invalid Payment ID</h2>
        <p>It seems like the payment ID is invalid. Please try again.</p>
      </div>
    )}

            
    </div>
  );
};

export default Payment;
