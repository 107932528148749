import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Box,
    Grid,
    Typography,
    Container,
    Link,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToastComponent from "../components/ToastComponent";
import logo from "../assets/images/icons/logo/white/WhiteShine256px.svg";
import Config from "../config/config";

const theme = createTheme({
    palette: {
        primary: {
            main: "#96d2b0",
        },
    },
});

export default function TwoFA() {
    const [twoFACode, setTwoFACode] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const navigate = useNavigate();

    const showToast = (message, type) => {
        setToastMessage(message);
        setToastType(type);
        setTimeout(() => {
            setToastMessage("");
            setToastType("");
        }, 9000);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                `${Config.API_URL}/api/auth/verify-2fa`,
                { email: localStorage.getItem("email"), twoFACode },
                { withCredentials: true }
            );

            if (response.status === 200) {
                localStorage.setItem("2fa-status", "success");
                showToast("2FA verification successful!", "success");
                navigate("/home");
            } else {
                showToast("Verification failed. Please try again.", "error");
            }
        } catch (error) {
            showToast(
                error.response?.data.errorMessage || "Error verifying 2FA code",
                "error"
            );
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar
                        sx={{
                            width: 90,
                            height: 90,
                            bgcolor: "#96d2b0",
                            border: "3px solid #5a8e70",
                        }}
                    >
                        <img src={logo} className={"height-width-5rem"} alt="GPGC Logo" />
                    </Avatar>
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ mt: 3, mb: 2, color: "#68a783" }}
                    >
                        Two-Factor Authentication
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="twoFACode"
                                    label="Enter 2FA Code"
                                    name="twoFACode"
                                    value={twoFACode}
                                    onChange={(e) => setTwoFACode(e.target.value)}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                            }}
                        >
                            Verify 2FA
                        </Button>
                        <Grid container className="signUpGrid">
                            <Grid item xs>
                                <Link
                                    href="/register"
                                    variant="body2"
                                    sx={{ color: "#68a783" }}
                                >
                                    Don't have an account? | Sign Up
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/login" variant="body2" sx={{ color: "#68a783" }}>
                                    Already Have An Account? | Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <ToastComponent message={toastMessage} type={toastType} />
            </Container>
        </ThemeProvider>
    );
}
